import React from "react";

export default function useDeviceDetect() {
  const [mobile, setMobile] = React.useState(null);

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;

    console.log(userAgent);
    if (Boolean(
      userAgent.match(
        /iPhone|iPad|iPod/i
      )
    )) setMobile('app_store');
    else if (Boolean(
      userAgent.match(
        /Android/i
      )
    )) setMobile('play_store');
    else setMobile('not_available');
  }, []);

  return { mobile };
}
