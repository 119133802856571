import useDeviceDetect from "../utils/device-detect";

const DownloadPage = () => {

  const { mobile } = useDeviceDetect();

  if (mobile === 'app_store') {
    window.location.href = 'https://apps.apple.com/app/id1512934824';
  }
  else if (mobile === 'play_store') {
    window.location.href = 'https://play.google.com/store/apps/details?id=it.miopediatra';
  }
  else if (mobile === 'not_available') {
    window.location.href = '/genitori';
  }
  return null;
}

export default DownloadPage
